<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">校长邀请</div>
    </div>
    <div class="contentBack">
      <div class="contentTop">校长圈</div>
      <div class="prompt">一个专为校长打造的高质量社交平台</div>
      <div class="contentName">“王校长”邀请您加入校长圈</div>
      <div class="ewm" ref="captureArea">
        <img src="../../image/1.png" alt="" />
      </div>
      <div class="smEwm">扫码识别加入校长圈</div>
      <div class="yqmBox">
        <div>邀请码</div>
        <div>48815</div>
        <div>复制</div>
      </div>
      <div class="yqmBot">请不要随意将此邀请码任意分享给任何人</div>
    </div>
    <div class="footerBtn">
      <div @click="generateImage()">保存图片</div>
      <div>分享给朋友</div>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { Toast } from "vant";
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({ path: "/myIndex" });
    },
    generateImage() {
      let e = this.$refs.captureArea;
      html2canvas(e, {
        allowTaint: true,
        useCORS: true, // 设置跨域
        tainttest: true, // 检测每张图片都已经加载完成
        backgroundColor: null, // 背景色, 转换图片可能会有白色底色，不要的话就null
      }).then((canvas) => {
        const link = document.createElement("a"); // 创建一个超链接对象实例
        link.download = "download.png"; // 设置要下载的图片的名称
        link.href = canvas.toDataURL(); // 将图片的URL设置到超链接的href中
        link.click(); // 触发超链接的点击事件
      });
      // const captureArea = this.$refs.captureArea;
      // const canvas = document.createElement("canvas");
      // const context = canvas.getContext("2d");

      // // 设置canvas的宽高与截图区域相同
      // canvas.width = captureArea.offsetWidth;
      // canvas.height = captureArea.offsetHeight;

      // // 将截图区域的内容绘制到canvas上
      // context.drawImage(captureArea, 0, 0, canvas.width, canvas.height);

      // // 将canvas转换为图片URL
      // const dataURL = canvas.toDataURL("image/png");

      // // 创建一个a标签，并设置下载属性
      // const link = document.createElement("a");
      // link.href = dataURL;
      // link.download = "screenshot.png";

      // // 触发点击事件，将图片保存到相册
      // link.click();
    },
  },
};
</script>

<style scoped>
.setBox {
  width: 750px;
  min-height: 100vh;
  background-image: url("../../image/xmrx/backImg.png");
  background-size: 100% 100%;
}
.boxTop {
  display: flex;
  align-items: center;

  padding-top: 26px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/my/g.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  margin-left: 232px;
}
.contentBack {
  width: 686px;
  height: 994px;
  background-image: url("../../image/xmrx/b.png");
  background-size: 100% 100%;
  margin-left: 32px;
  margin-top: 86px;
  overflow: hidden;
}
.contentTop {
  font-size: 36px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  margin-top: 48px;
}
.prompt {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  text-align: center;
  margin-top: 12px;
}
.contentName {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-top: 86px;
  text-align: center;
}
.ewm {
  width: 316px;
  height: 316px;
  background: red;
  margin: 0 auto;
  margin-top: 42px;
}
.ewm img {
  width: 100%;
  height: 100%;
}
.smEwm {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  text-align: center;
  margin-top: 30px;
}
.yqmBox {
  width: 558px;
  height: 80px;
  background: #f6f8fa;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  align-items: center;
}
.yqmBox div:nth-child(1) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 20px;
}
.yqmBox div:nth-child(2) {
  width: 380px;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 16px;
}
.yqmBox div:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: #0957c3;
}
.yqmBot {
  font-size: 24px;
  font-weight: 400;
  color: #ced5de;
  margin-top: 20px;
  text-align: center;
}
.footerBtn {
  display: flex;
  margin-top: 82px;
  padding-bottom: 50px;
}

.footerBtn div:nth-child(1) {
  width: 248px;
  height: 104px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 400;
  color: #455669;
  text-align: center;
  line-height: 104px;
  margin-left: 32px;
}
.footerBtn div:nth-child(2) {
  width: 422px;
  height: 104px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 104px;
  margin-left: 16px;
}
</style>
